import {
  REGISTER,
  LOGIN,
  FORGOTPASS,
  DOWNLOADER,
  PREMIUM,
  HOST_STATUS,
  PROFILE,
  CONTACT_US,
} from './constants'

const Faqs = [
  {
    id: 1,
    title: 'Getting Started',
    questions: [
      {
        id: 1.0,
        ques: 'What is Cboxera ?',
        content: `Cboxera.com is a premium link generator/ debrid service / multihost service which allows you to download files stored on popular filehosts at maximum speeds without needing to buy a premium account at all those individual filehosts.`,
        list: [],
        urls: [],
        img: [],
      },
      {
        id: 1.1,
        ques: 'How to create an account or register ?',
        content: `Its takes less than 2 minutes to register and it is completely free. Go to register option in the top menu and provide valid details and that's it your account will created.`,
        list: [],
        urls: [{ name: 'Register', url: REGISTER }],
        img: [],
      },
      {
        id: 1.2,
        ques: 'How to login ?',
        content:
          'You can click on Login option in the top menu and you can login with your verified email address and password.',
        list: [],
        urls: [{ name: 'Login', url: LOGIN }],
        img: [],
      },
      {
        id: 1.3,
        ques: 'How to verify email ?',
        content:
          "After registration we'll send a verification link to the email address you used to create the account. Just click on verification link and your email will be verified.",
        list: [],
        urls: [],
        img: [],
      },
      {
        id: 1.4,
        ques: "Can't login into my account ?",
        content:
          "Please ensure that you've have verified your email. If your account was already verified then try resetting your login password using Forgot Password option. If nothing works contact us immediately.",
        list: [],
        urls: [{ name: 'Forgot Password', url: FORGOTPASS }],
        img: [],
      },
      {
        id: 1.5,
        ques: 'Verification email not received ?',
        content:
          "First please check in your spam/junk folder for the verification mail. Sometimes it may take 10-15 minutes to get your verification email so please be patient. If you haven't received any mail after this time please use Forgot Password option.",
        list: [],
        urls: [{ name: 'Forgot Password', url: FORGOTPASS }],
        img: [],
      },
      {
        id: 1.6,
        ques: 'Is it free of cost ?',
        content:
          'Yes, our service is free to use but with certain limits. You can always upgrade your account Premium to remove limits.',
        list: [],
        urls: [{ name: 'Upgrade to Premium', url: PREMIUM }],
        img: [],
      },
      {
        id: 1.7,
        ques: 'Is your service legal ?',
        content:
          'Our service is legal, we do not store files that you download and we do not display links to illegal files. However, we and our host could not be held responsible if you download this type of content through our services.',
        list: [],
        urls: [],
        img: [],
      },
    ],
  },
  {
    id: 2,
    title: 'Downloading',
    questions: [
      {
        id: 2.0,
        ques: 'How to Download ?',
        content: 'It just takes few clicks to start downloading your links',
        list: [
          'Login into your account.',
          'Go to Downloader page from top menu.',
          'Paste your links you wanted to download in the textbox.',
          'Click on Download Links button.',
          'Click on Download button against the link to start your download.',
        ],
        urls: [
          { name: 'Login', url: LOGIN },
          { name: 'Downloader', url: DOWNLOADER },
        ],
        img: [],
      },
      {
        id: 2.1,
        ques: 'Can I download multiple links ?',
        content:
          "Yes, you can download multiple links at once but you have to be premium user. If you're a free user then you can download 1 link per 5 minutes.",
        list: [],
        urls: [],
        img: [],
      },
      {
        id: 2.2,
        ques: 'Why my download speed is slow ?',
        content:
          'Download speed is affected by many factors. We recommend downloading generated links using any Download Manager software so that you can attain maximum speed.',
        list: [],
        urls: [],
        img: [],
      },
      {
        id: 2.3,
        ques: 'Can I resume files after Interruption ?',
        content:
          'Yes, you can resume downloads and you also have the possibility to download multiple files at once without waiting time and at the best of your internet speed. All download links generated at cboxera are resumable. If you have problem with resuming please feel free to contact us.',
        list: [],
        urls: [],
        img: [],
      },
      {
        id: 2.4,
        ques: 'How long is the download link live after generation ?',
        content:
          'Ideally it is upto 24 hours from the time of generation but each filehosts have different sets of factors involved, so we cannot offer same link live time for every filehost. We will try to give best possible link live duration as to avoid any inconvenience.',
        list: [],
        urls: [],
        img: [],
      },
      {
        id: 2.5,
        ques: 'How to enable Download History ?',
        content:
          'By default Download History is disabled for all users. You can enable this option from your profile settings tab.',
        list: [
          'Login into your account.',
          'Navigate to Profile page from top menu.',
          'Go to settings tab.',
          'Enable/Disable your Download History option.',
        ],
        urls: [{ name: 'Profile', url: `${PROFILE}#settings` }],
        img: [],
      },
      {
        id: 2.6,
        ques: 'Unable to Generate Download links ?',
        content:
          'If you have problem while generating download links please ensure that your original link is active and supported by us. You can find supported filehosts information at host status page.',
        list: [],
        urls: [{ name: 'Host Status', url: HOST_STATUS }],
        img: [],
      },
      {
        id: 2.7,
        ques: 'What are Download/Bandwidth limits ?',
        content:
          'Download limits are the limits on filesize or total usage of particular filehost. You can find various filesize and daily bandwidth limits at host status page. Note that bandwidth limits can increase/decrease without any notice depending on the availability of the filehost. Bandwidth limit resets every day at 12:00:00 GMT+5:30',
        list: [],
        urls: [{ name: 'Host Status', url: HOST_STATUS }],
        img: [],
      },
    ],
  },
  {
    id: 3,
    title: 'Premium Services',
    questions: [
      {
        id: 3.1,
        ques: 'Do we offer Premium ?',
        content:
          'Yes, we offer premium service. Interested users can upgrade their account at any time using available Payment options directly on our site.',
        list: [],
        urls: [{ name: 'Upgrade to Premium', url: PREMIUM }],
        img: [],
      },
      {
        id: 3.2,
        ques: 'What is difference between Free and Premium ?',
        content:
          'Premium users can download without any limits (t&c) where as Free users has limits and waiting time for the next download. Below are the list of benefits you get on upgrading your account to Premium',
        list: [
          'Daily download upto 150GB bandwidth',
          'Premium hosts support',
          'Unlimited Streaming',
          'No waiting time',
          'No Advertisements',
          'Enable/Disable Download History',
        ],
        urls: [],
        img: [],
      },
      {
        id: 3.3,
        ques: 'How to upgrade my account to Premium ?',
        content: `You can upgrade your Free account to Premium anytime by just paying using
        available payments options like Paypal or Bitcoin or Paytm (only indian customers). You
        can see the benefits etc at premium page. We highly recommend please check support hosts status and limits before making any payment because we don't offer refunds once the payment is done.`,
        list: [],
        urls: [
          { name: 'Upgrade to Premium', url: PREMIUM },
          { name: 'Host Status', url: HOST_STATUS },
        ],
        img: [],
      },
      {
        id: 3.31,
        ques: 'Paid for Premium but account not upgraded ?',
        content:
          'In case if you paid and account still show free that means you paid at our Reseller site, in this case you will receive a Premium key from our resellers on your email address registered at cboxera.com which you can redeem on our premium page under the plans section.',
        list: [],
        urls: [{ name: 'Premium page', url: PREMIUM }],
        img: [],
      },
      {
        id: 3.32,
        ques: 'Premium Key not received from reseller ?',
        content:
          'If you have not received a Premium key from our reseller then please make sure you check on your email spam folders also. You will receive Premium key on your registered email address at cboxera.com not on your Paypal email. If still not received contact us directly so that we can help in getting your Premium key from reseller asap.',
        list: [],
        urls: [
          { name: 'Premium page', url: PREMIUM },
          { name: 'Contact', url: CONTACT_US },
        ],
        img: [],
      },
      {
        id: 3.33,
        ques: 'How to redeem Premium Key ?',
        content:
          'You can redeem your premium key on our premium page. Just under plans you will find Premium key section. Enter Premium key and click on redeem and you will be instantly upgraded to Premium if key is valid.',
        list: [],
        urls: [{ name: 'Redeem Premium Key at', url: PREMIUM }],
        img: [],
      },
      {
        id: 3.4,
        ques: 'Can I share my Premium account or generated links ?',
        content: `We do not allow any kind of account sharing or
        generated download links sharing with others. Our system has the capability to identify shared accounts and those accounts will be permanently blocked without any notice. So keep your account secure and timely change your passwords.`,
        list: [],
        urls: [
          { name: 'Profile', url: PROFILE },
          { name: 'Change Password', url: `${PROFILE}#change-password` },
        ],
        img: [],
      },
      {
        id: 3.5,
        ques: 'I will be charged every month if I pay for one time ?',
        content: `We never auto charge no matter what payment gateway you used for paying to Premium. So we don't support auto renewals you have to renew your Premium manually before or after
        expiration.`,
        list: [],
        urls: [],
        img: [],
      },
      {
        id: 3.6,
        ques: 'Can I renew my account before expiry ?',
        content:
          "Yes, you can renew your account even before expiry. You don't have to worry about days left in your account those days will be carry forwarded to new renewal. Example",
        list: [
          "Suppose you've 10 days in your account and you want to renew Premium again so, you've choosen 30 days plan then once your payment is done your account will credited with 40 days (30+10) including the past 10 days left in your account. So total 40 days will be credited to your account.",
        ],
        urls: [],
        img: [],
      },
    ],
  },
  {
    id: 4,
    title: 'Others',
    questions: [
      {
        id: 4.1,
        ques: 'How long it takes to activate my account after payment ?',
        content:
          'It depends on payment gateways. Usually your account upgrades instantly. If you paid via Bitcoin it may take upto 30 minutes to 1 hour for payment confirmation once your payment is confirmed your account will be automatically upgraded to Premium. In case of any issues please contact us.',
        list: [],
        urls: [{ name: 'Contact us', url: CONTACT_US }],
        img: [],
      },
      {
        id: 4.2,
        ques: 'My account not activated after payment ?',
        content:
          'Many factors can influence our scripts verifying that your payment is confirmed, we strongly recommend you contact us we are happy to help you as soon as possible.',
        list: [],
        urls: [],
        img: [],
      },
      {
        id: 4.3,
        ques: 'Do we support Streaming ?',
        content:
          "Yes, we support streaming for the links you generated at cboxera. We automatically detect streamable links and show streaming option. We only support .mp4 format we don't do any conversion at our end. Currently only Premium users can stream their links.",
        list: [],
        urls: [],
        img: [],
      },
      {
        id: 4.4,
        ques: 'How to stream via VLC ?',
        content:
          'Some video file formats can be streamable via VLC player. You need to have VLC player installed on your computer to stream. We show VLC option after generation for supported links. Follow instructions below.',
        list: [
          'Install VLC Player on your computer',
          'Generate an .mkv format file',
          'After generation click on VLC option it will download a small streamable file',
          'Open the downloaded file with your VLC player',
        ],
        urls: [],
        img: [],
      },
      {
        id: 4.5,
        ques: 'What is the difference between Filesize and Bandwidth limits ?',
        content:
          'Bandwidth limit is the total download limit per each day for each user for a particular file host. Filesize limit is the maximum size of the file which you can download. You can check the limits at host status page.',
        list: [
          'Please note that filesize and bandwidth limits changes (increase/decrease) time to time based on availability of the file host. Only few file hosts has these limits, rest of them are unlimited(max 100GB) just saying!',
        ],
        urls: [{ name: 'Host Status', url: HOST_STATUS }],
        img: [],
      },
      {
        id: 4.6,
        ques: 'Can I get free trail ?',
        content:
          "Sorry, we don't offer any free trails at the moment but stayed stuned for exciting offers on purchase of Premium.",
        list: [],
        urls: [],
        img: [],
      },
      {
        id: 4.7,
        ques: 'Can I get refund ?',
        content:
          "Refunds are not possible. So, please check host status page before upgrading because after payment we don't offer any kind refunds. So please go through all the limits and supported file hosts before paying. We don't recommend you to buy our Premium for particular or single file host. In case if you have any question just contact us we are happy to help you.",
        list: [],
        urls: [{ name: 'Contact us', url: CONTACT_US }],
        img: [],
      },
      {
        id: 4.8,
        ques: 'How can I change my password ?',
        content:
          'You can always change your password from your profile page. We recommend you to change your account password timely to avoid any misuse.',
        list: [],
        urls: [{ name: 'Change Password', url: `${PROFILE}#change-password` }],
        img: [],
      },
    ],
  },
]

export default Faqs
