/* eslint-disable prettier/prettier */
import React, { useCallback, useState, useRef, useEffect } from 'react'
import { Link } from 'gatsby'
import Faqs from '../data/faqs'
import Adsense from '../components/Adsense'
import { debounce } from '../helpers'

import SEO from '../components/SEO'

const BASE_URL = process.env.SITE_URL

const FAQSComponent = () => {
  const [collapId, setCollapId] = useState([])
  const [currentId, setCurrentId] = useState(1)

  const searchRef = useRef(null)
  const refs = useRef([])

  const addToRefs = el => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el)
      return refs.current
    }
  }

  const handleMenu = (sections, searchHeight) => {
    const cur = window.scrollY
    sections.forEach((section, index) => {
      const top = section.offsetTop + searchHeight
      const bottom = top + section.offsetHeight
      if (cur >= top && cur <= bottom) {
        setCurrentId(index + 1)
      } else if (cur <= searchHeight) {
        setCurrentId(1)
      }
    })
  }

  const debounceFn = useCallback((sections, searchHeight) => {
    debounce(handleMenu(sections, searchHeight), 200)
  }, [])

  useEffect(() => {
    const { offsetHeight } = searchRef.current
    window.addEventListener('scroll', () =>
      debounceFn(refs.current, offsetHeight)
    )
    return () => {
      window.removeEventListener('scroll', () => debounceFn(refs.current, 211))
    }
  }, [searchRef])

  const collapseQuestion = id => {
    if (collapId.includes(id)) {
      return setCollapId(collapId.filter(x => x !== id))
    }
    setCollapId([...collapId, id])
  }

  const handleClick = id => () => {
    collapseQuestion(id)
  }

  const handleChange = id => e => {
    e.preventDefault()
    const currentDiv = refs.current[id - 1].offsetTop - window.scrollY
    const { offsetHeight } = searchRef.current
    window.scrollBy({
      top: currentDiv + offsetHeight,
      behavior: 'smooth',
    })
  }
  return (
    <>
      <SEO page="faqs" />
      <section className="faq">
        <div className="bg-dark p-5 mb-4" ref={searchRef}>
          <div className="col-sm-12 p-2">
            <h2 className="text-light text-center">
              Frequently Asked Questions
            </h2>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-4">
              <div className="headings sticky">
                <ul>
                  {Faqs.map(faq => (
                    <li
                      key={faq.id}
                      onClick={handleChange(faq.id)}
                      className={`${currentId === faq.id ? 'active' : ''}`}
                      role="presentation"
                    >
                      {faq.title}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-8">
              {Faqs.map(faq => (
                <div className="questions" key={faq.id} ref={addToRefs}>
                  <h5>{faq.title}</h5>
                  {faq.questions.map(ques => (
                    <div
                      key={ques.id}
                      className="ques"
                      onClick={handleClick(ques.id)}
                      role="presentation"
                    >
                      <p
                        className={`qtext ${
                          collapId.includes(ques.id) ? 'active' : ''
                        }`}
                      >
                        {ques.ques}
                      </p>
                      <div
                        className="content"
                        style={{
                          maxHeight: `${
                            collapId.includes(ques.id) ? '350px' : '0px'
                          }`,
                        }}
                      >
                        <div className="faqAnswer">
                          {ques.content}
                          {ques.list.length > 0 && (
                            <ul>
                              {ques.list.map((li, index) => (
                                <li key={String(index)}>
                                  <i className="far fa-arrow-alt-circle-right pr-1" />
                                  {li}
                                </li>
                              ))}
                            </ul>
                          )}
                          {ques.urls.length > 0 && (
                            <div className="usefulLinks">
                              <i className="fas fa-link pr-1" />
                              Useful Links: <br />
                              <ul>
                                {ques.urls.map((url, index) => (
                                  <li key={String(index)}>
                                    <i className="far fa-hand-point-right pr-1" />
                                    {url.name}{' '}
                                    <Link className="interLink" to={url.url}>
                                      {BASE_URL}
                                      {url.url}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div>
                    <Adsense slot="5602040533" format="fixed" />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default FAQSComponent
